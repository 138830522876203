import Image from 'next/image';
import { render } from 'storyblok-rich-text-react-renderer';
import React, { FunctionComponent } from 'react';
import { FooterStoryblok } from '@/types/types-storyblok';
import { DAVINCI_CDN } from '@/costants/defaultValues';
import Link from 'next/link';
import { resolveWebLink } from '@/storyblok/utils/link';

const FooterTop: FunctionComponent<{ component: FooterStoryblok }> = ({ component: footer }) => {
  return (
    <footer className="bg-neutral-surface" data-cy="footer">
      <div className="container mx-auto px-1 md:px-2 lg:px-4">
        <div className="flex flex-col gap-10 py-12 md:grid">
          <div className="flex flex-col gap-5 md:col-start-1 md:row-start-1">
            <Image src={`${DAVINCI_CDN}/elty/elty-logo.svg`} width={108} height={33} alt="Logo" />
            <div className="text-2xl lg:hidden">{render(footer.slogan)}</div>
          </div>

          <div className="col-start-1 row-start-3 hidden text-2xl text-primary lg:block">{render(footer.slogan)}</div>

          <div className="flex flex-col gap-4 md:col-start-1 md:row-start-2 lg:col-start-2 lg:row-start-1 lg:flex-grow lg:flex-row lg:items-center lg:justify-end lg:gap-9">
            <div className="w-1/2 font-semibold text-primary lg:w-auto">{render(footer.appDownloadCta)}</div>
            <div className="flex flex-row gap-1.5">
              <Link href={footer.appIosUrl?.url} target="_blank" rel="noopener noreferrer" className="h-7">
                <Image
                  src={`${DAVINCI_CDN}/elty/download-on-app-store.png`}
                  width={183}
                  height={56}
                  alt="Download on App Store"
                  className="h-7 w-auto"
                />
              </Link>
              <Link href={footer.appAndroidUrl?.url} target="_blank" rel="noopener noreferrer" className="h-7">
                <Image
                  src={`${DAVINCI_CDN}/elty/download-on-google-play.png`}
                  width={183}
                  height={56}
                  alt="Download on Google Play"
                  className="h-7 w-auto"
                />
              </Link>
            </div>
          </div>

          <ul className="flex flex-row gap-2 md:col-start-1 md:row-start-3 lg:col-start-4 lg:row-start-1 lg:justify-end">
            {footer.socials?.map(social => (
              <li key={social._uid} title={social.Name}>
                {social.Link && (
                  <Link href={resolveWebLink(social.Link, { name: 'footer social', social })} target="_blank" rel="noopener noreferrer">
                    {social.Icon && social.Name && <Image src={social.Icon?.filename} alt={social.Name} width={30} height={30} />}
                  </Link>
                )}
              </li>
            ))}
          </ul>

          <hr className="hidden w-full lg:col-span-4 lg:col-start-1 lg:row-start-2 lg:mb-1 lg:block lg:h-0.5 lg:bg-slate-500 " />

          <nav className="md:col-span-2 md:col-start-2 md:row-span-3 md:row-start-1 lg:col-span-3 lg:col-start-2 lg:row-span-2 lg:row-start-3 lg:flex lg:flex-row lg:justify-end">
            <ul className="flex flex-col gap-8 md:flex-row lg:w-full lg:max-w-[700px] lg:justify-between lg:pl-20 xl:pl-0">
              {footer.sections?.map(section => (
                <li key={section._uid} className="flex flex-col gap-5">
                  <h2 className="font-semibold text-brandGreen-400">{section.title}</h2>
                  <nav>
                    <ul role="menu" className="flex flex-col gap-3 ">
                      {section.links?.map(link => (
                        <li key={link._uid} role="presentation">
                          {link?.title && link?.link?.url && (
                            <Link
                              role="menuitem"
                              href={resolveWebLink(link.link, { name: 'footer section menu item', link })}
                              className="text-primary"
                            >
                              {link.title}
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default FooterTop;
